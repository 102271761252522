




import { Component, Vue } from "vue-property-decorator";
import { IUser } from "@/types/User";

@Component
export default class UserHome extends Vue {
  get user(): IUser {
    return this.$store.getters["user/user"];
  }
}
